.formContainer input:disabled {
	color: black;
    background-color: rgba(255,255,255, 0.3);
}

.formContainer {
	background-color: #0a2955;
	padding: 10px;
}
.formContainer .removeLeftPadding {
	padding-left: 0px !important;
}

.formContainer .bold {
	color: #ffffff;
	font-size: 12px;
	font-weight: bold;
}
.formContainer .formHeader {
	display: flex;
	padding: 10px;
}
.formContainer .headerTitle {
	color: #ffffff;
	font-size: 18px;
	flex: 2;
}
.formContainer .closeForm {
	color: #ffffff;
	cursor: pointer;
	font-size: 18px;
	flex: 1;
	text-align: right;
}
.formContainer td {
	padding: 5px !important;
}
.formContainer .btn {
	font-size: 10px !important;
}
.checkContainer {
	display: flex;
	width: 70px;
	height: 25px;
	border: 1px solid lightgray;
	border-radius: 2px;
	cursor: pointer;
}
.checkContainer svg {
	color: #ffffff;
	margin: auto;
}

.hintForm {
	font-size: 10px;
	font-style: italic;
	color: lightgray;
}

.form-link div {
	cursor: pointer;
	color: #002955;
	font-size: 14px;
}
.form-link div:hover {
	text-decoration: underline;
}

.whiteDropdown button {
	color: #ffffff;
	border-color: #ffffff;
}
.autofillFieldBackground {
	background-color: #6076ab;
}
.smallText {
	color: white;
	font-size: 11px;
}

.input-number-planning > div:first-of-type {
	display: none;
}
.input-number-planning > div {
	line-height: 2;
	margin-top: 6%;
}
.input-number-planning > div > input {
	width: 40px;
	font-size: 13px;
}

.dropdown-planning > button {
	white-space: break-spaces !important;
}

.header-text {
	color: white;
	font-size: 13px;
	font-weight: 700;
	display: flex;
	margin-left: 5px;
}
.grid-div {
	text-align: center;
	display: grid;
	justify-content: center;
	align-items: center;
	position: relative;
}
.grid-div.first-group > div:first-child {
	height: 30px;
	padding-bottom: 5px;
}
.grid-div.first-group > div:nth-child(3) {
	height: 15px;
	display: flex;
}
.grid-div.second-group > div:first-child {
	height: 30px;
}
.grid-div.second-group > div:nth-child(2) {
	height: 100%;
	display: flex;
}
.grid-div.forth-group > div:first-child {
	height: 60px;
}
.grid-div.forth-group > div:nth-child(2) {
	height: 100%;
}

.line-border {
	width: 100%;
	border: 1px solid white;
	height: 1px;
	position: absolute;
	left: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
	width: 36px;
	font-size: 13px;
	height: 30px;
	border-radius: 3px;
	border: 1px solid grey;
	text-align: center;
	margin-top: 3px;
}
