/* Home Page */
.form-body {
	height: 100%;
}
.form-body > .row {
	position: relative;
	margin-left: 0;
	margin-right: 0;
	height: 100%;
}
.img-holder {
	width: 550px;
	background-color: #000;
}
.img-holder {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 550px;
	min-height: 700px;
	height: 100%;
	overflow: hidden;
	background-color: #000000;
	/* padding: 60px; */
	text-align: center;
	z-index: 999;
}
.img-holder .bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
	z-index: -1;
}
.img-holder .info-holder {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.form-holder {
	margin-left: 550px;
}
.form-holder {
	width: 100%;
	background: white;
}
.form-holder .form-content {
	position: relative;
	text-align: center;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	padding: 60px;
	min-height: 100%;
}
.form-content .form-items {
	max-width: 340px;
	text-align: left;
}
.form-content .form-items {
	display: inline-block;
	width: 100%;
	max-width: 340px;
	text-align: left;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.form-content .form-items {
	max-width: 340px;
	text-align: left;
}
.form-content .form-items {
	display: inline-block;
	width: 100%;
	max-width: 340px;
	text-align: left;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.website-logo-inside {
	margin-bottom: 20px;
}
.form-content h3 {
	color: #0A2955;
	text-align: left;
}
.form-content h3 {
	text-align: left;
	font-size: 24px;
	font-weight: 900;
	margin-bottom: 10px;
}
.website-logo-inside a {
	display: inline-block;
}
.website-logo-inside .logo {
	background-image: url(../SVG/logo-dark3.svg);
}
.website-logo-inside .logo {
	display: inline-block;
	background-image: url(../SVG/logo-light2.svg);
	background-size: contain;
	background-repeat: no-repeat;
}
.website-logo-inside .logo img.logo-size {
	opacity: 0 !important;
}
.website-logo-inside .logo img {
	width: 250px;
}

.relativeContainer {
	height: 100%;
}

.form-content p {
	color: #000;
	text-align: left;
}
.form-content p {
	font-size: 18px;
	font-weight: 300;
	line-height: 20px;
	margin-bottom: 30px;
}

.avatar-labor-market-container {
	width: 30px;
}
.character-labor-market {
	width: 100%;
}
.avatar-labor-market-count {
	width: 22px;
	height: 22px;
	border-radius: 11px;
	border: 1px solid #000;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1px;
	font-size: 11px;
}
.labor-market-wrapper {
	height: 90px;
}
.market-region {
	height: 245px;
}


.park-field-wrapper {
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 2px);
}