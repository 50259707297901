* {
	margin: 0;
	padding: 0;
}
.App {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: relative;
}
.App::after {
	content: "";
	opacity: 0.4;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;
	background-image: url("./assets/images/bg-main.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.header-bar {
	padding: 10px;
}
.header-bar > .side-block {
	background-image: url("./assets/images/sm-bg-1.png");
	background: transparent\9;
	border: solid white 2px;
	background-size: cover;
	background-position: top;
	display: grid;
}
.center-block {
	background-image: url("./assets/images/background.png");
	background-size: cover;
	background-position: top;
}
.center-block > div {
	border-top: solid white 6px;
	border-bottom: solid white 6px;
	border-right: solid white 14px;
	border-left: solid white 14px;
}
.side-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 2;
	margin-top: 200px;
}
.left-side-bar {
	margin-right: -7px;
	margin-left: 1%;
}
.right-side-bar {
	margin-left: -7px;
	margin-right: 1%;
}
.side-bar-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.side-bar-block > .blueish {
	display: flex;
	background: #335a97;
	color: white;
	height: fit-content;
	flex-direction: column;
	padding: 3px;
}
.region-blocks {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.single-region-block:nth-child(odd) {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-right: solid white 6px;
	border-top: solid white 6px;
}
.single-region-block:nth-child(even) {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	border-top: solid white 6px;
}

.side-block {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: relative;
	padding-left: 2px;
}
.single-region-block:nth-child(odd) > .region-block {
	border-left: solid white;
}
.single-region-block:nth-child(even) > .region-block {
	border-right: solid white;
}
.side-blocks {
	max-width: 280px;
	margin: 3px;
	row-gap: 3px;
}
.side-blocks-title {
	font-size: 0.65vw;
	text-transform: uppercase;
	color: white;
	padding: 2px;
	width: 50%;
}
.draggable-block {
	background: #335a97;
	color: white;
	padding: 3px !important;
	display: table;
}

.rectangle {
	height: 40px;
	width: 75px !important;
	background-color: white;
	position: relative;
}
.side-rectangle {
	width: 100%;
	background-color: white;
}

.sm-header {
	font-size: 0.45vw;
	color: black;
	margin-bottom: 0;
	font-weight: 600;
	padding: 1px;
	text-align: start;
}

.sm-img {
	width: 25px;
	margin: auto;
}

/* ----REGION------- */
.region-block {
	flex: 2;
	justify-content: space-between;
}
.region-block-title {
	font-size: 0.93vw;
	text-transform: uppercase;
	font-weight: 700;
}
.customer-block {
	background: #d1d2d4;
	margin-left: 2px;
	margin-top: 1.5rem;
	z-index: 2;
	max-width: 10rem !important;
}
.customer-rectangle {
	height: 140px;
	background-color: white;
}

.eq-block {
	background: #d0d2d3;
	color: black;
	padding-top: 4px;
	text-align: center;
}
.eq-rectangle {
	height: 30px;
	width: 80px;
	background-color: white;
	text-align: center;
}
.white-line {
	border: solid white 1px;
	width: 102%;
	margin-left: -2px;
}

.yellowish {
	background-color: #ffc44e;
}
.greyish {
	background-color: #d0d2d3;
}
.whitish {
	background-color: white !important;
}
.reddish {
	background-color: #9c4a3c;
}
.blueish {
	background-color: #002857 !important;
}
.transparent {
	background-color: transparent !important;
	height: 0;
	padding: 0 !important;
}
.light-bluish {
	background-color: #e3e9ef;
}

.reddish-border {
	border: solid #bc1700;
	height: fit-content;
}
.height-100 {
	height: 100%;
}
.r-2 {
	right: 2px;
	top: 0;
}
.line-0 {
	line-height: 0;
}

.square {
	width: 20px;
	height: 20px;
	background-color: #e3e9ef;
	border: solid #355a96 1px;
	text-align: center;
	line-height: 1;
}
.squares-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	bottom: -6px;
	padding: 9px;
}
.arrow-up {
	position: relative;
	left: 10px;
	width: 85px;
}
.border-space {
	border-left: 3px solid transparent;
	background-clip: padding-box;
}

.tran-block > * {
	background-color: transparent;
	color: transparent;
}

@media (min-width: 1800px) {
	.region-id {
		line-height: 1;
	}
	.customer-block {
		margin-top: 1.8rem !important;
	}
}

.flex-grow-10 {
	flex-grow: 10 !important;
}

.labor-btn {
	align-self: flex-end;
}
.labor-btn > img {
	width: 183px;
	border: solid white 2px;
	cursor: pointer;
}
.labor-btn > span {
	position: absolute;
	text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
}
.market-container {
	background-image: url("./assets/images/button.png");
	background-size: cover;
	background-repeat: no-repeat;
	padding: 4% 18% 4% 18% !important ;
	background-position: center;
}
.market-container > span {
	font-size: 2vw;
	-webkit-text-stroke: 0.6px black;
	text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
	text-transform: uppercase;
	font-weight: 500;
}
.market-region-blocks {
	row-gap: 10px;
}
.custom-border-space {
	border-left: 10px solid transparent;
	background-clip: padding-box;
}
.market-region {
	background-color: rgb(255, 255, 255, 75%);
	height: 30vh;
}
.market-region {
	color: #002957;
	font-size: 1vw;
	-webkit-text-stroke: 0.5px black;
	text-transform: uppercase;
	font-weight: 500;
	display: grid;
	height: 100%;
}

.market-avatar {
	width: 60px;
}

.modal-dialog {
	max-width: fit-content !important;
	max-width: -webkit-fit-content !important;
}
.eq-location {
	min-width: -moz-available;
}

.input-inside {
	width: 100%;
	height: 45px;
	text-align: center;
	border: 2px solid white !important;
	font-size: 1.2vw;
	font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
input[type="text"]:focus {
	outline: 1px solid black !important;
}

.liability {
	font-weight: bold;
	background: #ec1c29;
	border: solid 1px white;
}

.emoji {
	position: absolute;
	width: 17px;
	right: 0;
	top: -2px;
}

.minus-icon {
	position: absolute;
	color: black;
	width: 16px;
	height: 16px;
	font: 16px/1 Arial, sans-serif;
	text-align: center;
	border-radius: 50%;
	background: gainsboro;
	z-index: 2;
	bottom: 45%;
	font-weight: 700;
	border-color: transparent;
}
.minus-icon.ml-1 {
	left: 0;
	line-height: 0;
}
.minus-icon.mr-1 {
	right: 0;
	line-height: 0.93;
}
.minus-icon:focus {
	outline: 0;
}

.draggable-block.yellowish,
.draggable-block.reddish {
	border-radius: 5px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
}

.Location {
	background-image: url("./assets/SVG/Asset 8.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 25px;
}
.Equipment {
	background-image: url("./assets/SVG/Asset 9.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 25px;
}
.avatar-pic {
	width: 22px;
	margin-top: 2px;
	margin-right: 4px;
	border: solid 1px white;
	border-radius: 100%;
}

.padding-1 {
	padding: 1px;
}

.greyish > div > div > input.rc-input-number-input {
	font-size: 2rem;
	text-align: center;
	width: 100%;
	font-weight: 500;
}
.form-input > div > input.rc-input-number-input {
	width: 100%;
	margin-left: 1px;
	height: 33px;
	text-align: center;
	margin: -1px 0;
}

.d-grid {
	display: grid !important;
	grid-template-columns: 185% 78%;
	width: 4rem !important;
}

.w-100 {
	width: 100% !important;
}

.annualField > div > input {
	padding: 2px 5px;
	font-size: 0.875rem;
	border-radius: 6px;
}

.coins-sum {
	position: absolute;
	right: 3px;
	color: white;
	font-weight: 600;
	font-size: 12px;
	top: 0;
	text-align: center;
	animation: show 300ms ease-in;
	animation-fill-mode: forwards;
	z-index: 3;
	-webkit-text-stroke: 0.1px white;
	background: #335a97;
}

@keyframes show {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

.avatar-placeholder {
	display: inline-flex;
	border: solid 1px darkgreen;
	border-radius: 100%;
	height: 22px;
	width: 22px;
	opacity: 0.5;
	margin-top: 2px;
	margin-right: 4px;
}

.__react_component_tooltip {
	padding: 4px !important;
}

.top-block {
	position: relative;
}
.bottom-block {
	position: relative;
}

.viewOnlyContainer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.viewOnlyContainer span {
	color: #0a2955;
	font-weight: 900;
	font-size: 20px;
}

.checkListRow p {
	margin-bottom: 0px !important;
}
.checkListRow ul {
	margin-bottom: 0px !important;
}
