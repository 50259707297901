/******* Header ********/
.containerDraggable {
  width: 123px;
  height: 60px;
  margin: 0 auto;
}
.tokenDraggable {
  width: 74px;
  height: 45px;
  margin: 0 auto;
}

.containerDraggable img,
.tokenDraggable img {
  width: 95%;
  height: 95%;
  cursor: grab;
}

.draggable-pins-container {
  display: flex;
}
.pinDraggable {
  width: 30px;
  /*height: 48px;*/
  margin: 0 auto;
}
.pinDraggable img {
  width: 100%;
  height: 100%;
  cursor: grab;
}

.labelDraggable {
  width: 48px;
  height: 48px;
  margin: 0 auto;
}
.labelDraggable img {
  width: 100%;
  height: 100%;
  cursor: grab;
}

.Vertical-droppable-field .objModelClass,
.Production-droppable-field .objModelClass {
  width: 95%;
  height: 100%;
}

.droppable-field .objModelClass,
.Finance-droppable-field .objModelClass,
.Search-droppable-field .objModelClass,
.Purchase-droppable-field .objModelClass,
.Bottom-droppable-field .objModelClass,
.Colored-droppable-field .objModelClass {
  width: 100%;
  height: 95%;
  vertical-align: middle;
}

.Finance-droppable-field .helper,
.Search-droppable-field .helper,
.Purchase-droppable-field .helper,
.Bottom-droppable-field .helper,
.Vertical-droppable-field .helper,
.Colored-droppable-field .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.Coins-droppable,
.Coins-vertical-droppable {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.Coins-vertical-droppable {
  display: table;
}

.Coins-droppable {
  display: flex;
}
.Coins-droppable .coin {
  width: 6px !important;
  height: 30px !important;
  vertical-align: middle;
  margin: auto 0;
}
.Coins-droppable .coin.gold {
  background-image: url("../images/dollar_coin_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.Coins-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.Coins-droppable .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.zoom-20 .Coins-droppable .coin.gold {
  background-image: url("../images/dollar_coin_h_zoom.png") !important;
}
.zoom-20 .Coins-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_h_zoom.png") !important;
}
.zoom-20 .Coins-droppable .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h_zoom.png") !important;
}

.zoom-40 .Coins-droppable .coin.gold {
  background-image: url("../images/dollar_coin_h_zoom.png") !important;
}
.zoom-40 .Coins-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_h_zoom.png") !important;
}
.zoom-40 .Coins-droppable .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h_zoom.png") !important;
}

.zoom-60 .Coins-droppable .coin.gold {
  background-image: url("../images/dollar_coin_h_zoom.png") !important;
}
.zoom-60 .Coins-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_h_zoom.png") !important;
}
.zoom-60 .Coins-droppable .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h_zoom.png") !important;
}

.zoom-80 .Coins-droppable .coin.gold {
  background-image: url("../images/dollar_coin_h_zoom.png") !important;
}
.zoom-80 .Coins-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_h_zoom.png") !important;
}
.zoom-80 .Coins-droppable .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h_zoom.png") !important;
}

.zoom-100 .Coins-droppable .coin.gold {
  background-image: url("../images/dollar_coin_h_zoom.png") !important;
}
.zoom-100 .Coins-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_h_zoom.png") !important;
}
.zoom-100 .Coins-droppable .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h_zoom.png") !important;
}

.zoom-20 .Coins-droppable .coin.not-last-coin:last-child {
  width: 11px !important;
}
.zoom-40 .Coins-droppable .coin.not-last-coin:last-child {
  width: 13px !important;
}
.zoom-60 .Coins-droppable .coin.not-last-coin:last-child {
  width: 14px !important;
}
.zoom-80 .Coins-droppable .coin.not-last-coin:last-child {
  width: 15px !important;
}
.zoom-100 .Coins-droppable .coin.not-last-coin:last-child {
  width: 16px !important;
}

.Coins-vertical-droppable .coin {
  /*display: table-cell;*/
  display: block;
  width: 30px !important;
  height: 9px !important;
  margin: 0 auto;
}
.Coins-vertical-droppable .coin.gold {
  background-image: url("../../assets/images/dollar_coin_v.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.Coins-vertical-droppable .coin.silver {
  background-image: url("../../assets/images/dollar_coin_silver_v.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.Coins-vertical-droppable .coin.bronze {
  background-image: url("../../assets/images/dollar_coin_bronze_v.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.zoom-20 .Coins-vertical-droppable .coin.gold {
  background-image: url("../../assets/images/dollar_coin_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-20 .Coins-vertical-droppable .coin.silver {
  background-image: url("../../assets/images/dollar_coin_silver_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-20 .Coins-vertical-droppable .coin.bronze {
  background-image: url("../../assets/images/dollar_coin_bronze_v_zoom.png") !important;
  background-size: cover !important;
}

.zoom-40 .Coins-vertical-droppable .coin.gold {
  background-image: url("../../assets/images/dollar_coin_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-40 .Coins-vertical-droppable .coin.silver {
  background-image: url("../../assets/images/dollar_coin_silver_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-40 .Coins-vertical-droppable .coin.bronze {
  background-image: url("../../assets/images/dollar_coin_bronze_v_zoom.png") !important;
  background-size: cover !important;
}

.zoom-60 .Coins-vertical-droppable .coin.gold {
  background-image: url("../../assets/images/dollar_coin_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-60 .Coins-vertical-droppable .coin.silver {
  background-image: url("../../assets/images/dollar_coin_silver_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-60 .Coins-vertical-droppable .coin.bronze {
  background-image: url("../../assets/images/dollar_coin_bronze_v_zoom.png") !important;
  background-size: cover !important;
}

.zoom-80 .Coins-vertical-droppable .coin.gold {
  background-image: url("../../assets/images/dollar_coin_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-80 .Coins-vertical-droppable .coin.silver {
  background-image: url("../../assets/images/dollar_coin_silver_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-80 .Coins-vertical-droppable .coin.bronze {
  background-image: url("../../assets/images/dollar_coin_bronze_v_zoom.png") !important;
  background-size: cover !important;
}

.zoom-100 .Coins-vertical-droppable .coin.gold {
  background-image: url("../../assets/images/dollar_coin_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-100 .Coins-vertical-droppable .coin.silver {
  background-image: url("../../assets/images/dollar_coin_silver_v_zoom.png") !important;
  background-size: cover !important;
}
.zoom-100 .Coins-vertical-droppable .coin.bronze {
  background-image: url("../../assets/images/dollar_coin_bronze_v_zoom.png") !important;
  background-size: cover !important;
}

.zoom-20 .Coins-vertical-droppable .coin.not-last-coin:last-child {
  height: 11px !important;
}
.zoom-40 .Coins-vertical-droppable .coin.not-last-coin:last-child {
  height: 13px !important;
}
.zoom-60 .Coins-vertical-droppable .coin.not-last-coin:last-child {
  height: 14px !important;
}
.zoom-80 .Coins-vertical-droppable .coin.not-last-coin:last-child {
  height: 15px !important;
}
.zoom-100 .Coins-vertical-droppable .coin.not-last-coin:last-child {
  height: 16px !important;
}

.Coins-droppable .coin:nth-child(2) {
  margin-left: 1px;
}
.Coins-droppable .coin:nth-child(3) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(4) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(5) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(6) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(7) {
  margin-left: 1px;
}
.Coins-droppable .coin:nth-child(8) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(9) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(10) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(11) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(12) {
  margin-left: 2px;
}
.Coins-droppable .coin:nth-child(13) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(14) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(15) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(16) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(17) {
  margin-left: 1px;
}
.Coins-droppable .coin:nth-child(18) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(19) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(20) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(21) {
  margin-left: -4px;
}

.Coins-vertical-droppable .coin:nth-child(1) {
  margin-top: 0px;
}
.Coins-vertical-droppable .coin:nth-child(2) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(3) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(4) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(5) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(6) {
  margin-top: -1px;
}
.Coins-vertical-droppable .coin:nth-child(7) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(8) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(9) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(10) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(11) {
  margin-top: 0px;
}
.Coins-vertical-droppable .coin:nth-child(12) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(13) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(14) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(15) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(16) {
  margin-top: 0px;
}
.Coins-vertical-droppable .coin:nth-child(17) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(18) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(19) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(20) {
  margin-top: -7px;
}

.Coins-vertical-droppable .Container-Card {
  width: 30px !important;
  height: 30px !important;
  transform: rotateX(70deg);
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -15px;
}

.rent-vertical-droppable .Container-Card {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  bottom: 0px;
  left: 3px;
}

.containerVerticalProductCardDraggable {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  right: -8px;
  transform: rotateY(65deg) rotateZ(-90deg);
  top: 50%;
  margin-top: -15px;
}
.containerProductCardDraggable {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  bottom: -8px;
  transform: rotateX(70deg);
  left: 50%;
  margin-left: -15px;
}
.containerProductCardDraggableImg {
  width: 100%;
  height: 100%;
}
.containerProductCardValue {
  font-size: 16px;
  text-align: center;
  top: 50%;
  position: absolute;
  width: 100%;
  height: 24px;
  margin-top: -12px;
  font-weight: bolder;
  color: black;
}

.dragging {
  cursor: grabbing;
}

.Coins-vertical-droppable .Container-Card-2 {
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  bottom: 2px;
  left: 50%;
  margin-left: -15px;
}

.token-counter-text {
  text-align: center;
  line-height: 2em;
}
.token-counter-btn {
  background-color: #0a2955;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  margin: 5px auto;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: 900;
  line-height: 2em;
}
