.CommonEventPopup .modal-content {
	background-image: url("../../assets/images/sm-bg-1.png");
	width: 720px;
	height: fit-content;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #0f345e;
	font-weight: 700;
}

.CommonEventPopup .content {
	padding: 25px;
}

.CommonEventPopup .header-container {
	display: flex;
}
.CommonEventPopup .header-container h3 {
	margin: auto 0px;
}
.CommonEventPopup .header-container img {
	width: 50px;
	margin-right: 10px;
}
.CommonEventPopup .content-body {
	margin-top: 40px;
}

.impact-row {
	display: flex;
}
.impact-title {
	width: 80px;
	font-weight: bold;
}
