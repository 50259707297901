.avatar-container {
	position: relative;
    margin: 3px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yellow-avatar-container {
	width: 136px;
    height: 63px;
}
.grey-avatar-container {
	width: 190px;
	height: 88px;
}
.green-avatar-container {
	width: 250px;
	height: 109px;
}


.yellow-block {
	border-bottom: 62px solid #fed105;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	width: 100%;
	position: absolute;
}
.grey-block {
	border-bottom: 88px solid #919396;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	width: 100%;
	position: absolute;
}
.green-block {
	border-bottom: 109px solid #51b948;
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	width: 100%;
	position: absolute;
}

.avatars-wrapper {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 72%;
}

.locationPriceContainer {
	font-size: 6px;
	margin-top: 6px;
	float: right;
	margin-right: 2px;
}
.equipmentPriceContainer {
	font-size: 6px;
	margin-top: 10px;
	float: right;
	margin-right: 2px;
}


.location-header {
	display: flex;
	justify-content: space-between;
}

.help-icon {
	display: flex;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    font-size: 8px;
    font-weight: bold;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    margin-right: 3px;
    align-items: center;
    justify-content: center;
	cursor: pointer;
}


.yellowBorder {
	border: 2px solid #ffff00 !important;
}
.redBorder {
	border: 2px solid #ff0000 !important;
}
