.chatNotification {
	position: absolute;
	top: 18px;
    left: 0px;
	background-color: #d50000;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 9px;
}

.chat-button {
	position: fixed;
	bottom: 0px;
	right: 50px;
	height: 40px;
	width: 200px;
	background-color: #e8e8e8;
	color: #0a2955;
	font-size: 16px;
	text-align: center;
	line-height: 2.5em;
	cursor: pointer;
	z-index: 90;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border: 1px solid #002955;
}

.chat-popup {
	position: fixed;
	bottom: 0;
	right: 50px;
	border: 3px solid #002955;
	z-index: 91;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	overflow: hidden;
	width: 240px;
}

.chat-popup-header {
	background-color: #002955;
	width: 100%;
	height: 40px;
	display: flex;
	padding: 5px;
}
.chat-popup-header-title {
	width: 50%;
	font-size: 14px;
	color: #ffffff;
	line-height: 2em;
}
.chat-popup-header-btn {
	position: relative;
	width: 50%;
}
.chat-popup-header-btn button {
	color: #ffffff;
	font-size: 14px;
	border: none;
	background-color: transparent;
	position: absolute;
	right: 5px;
	top: 50%;
	margin-top: -12px;
}

.chat-messages {
	height: 300px;
	width: 100%;
	background-color: #ffffff;
	padding: 0px 10px 0px 10px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.chat-message {
	border: 2px solid #dedede;
	background-color: #f1f1f1;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
	width: fit-content;
	line-break: anywhere;
	display: grid;
}

.chat-message .playerName {
	color: rgb(170, 170, 170);
	font-size: 10px;
}

.chat-message .message {
	font-size: 12px;
	color: #000000;
	overflow-wrap: break-word;
	white-space: pre-line;
}

.chat-form {
	/*height: 40px;*/
	width: 100%;
	border-top: 1px solid #f1f1f1;
}

.form-container {
	padding: 5px;
	background-color: white;
	display: flex;
	height: 100%;
	margin: auto 0;
}

.form-container textarea {
	border: none;
	background: #f1f1f1;
	resize: none;
	width: 100%;
	font-size: 12px;
	overflow: hidden;
	border-radius: 15px;
	padding: 5px;
}

.form-container textarea:focus {
	background-color: #ddd;
	outline: none;
}

.form-container .btn {
	background-color: #4caf50;
	color: white;
	border: none;
	cursor: pointer;
	opacity: 0.8;
	width: 60px;
	margin-left: 5px;
	font-size: 12px;
	height: 30px;
}

.form-container .cancel {
	background-color: red;
}
